import React from 'react';
import { Parallax } from 'react-parallax';
import './LandingPage.css';
import background from './background.jpg'; // Ensure the path is correct
import Heart3D from './Heart3D';

const LandingPage = () => {
  return (
    <Parallax bgImage={background} strength={500}>
      <div className="landing-page">
        <Heart3D />
      </div>
    </Parallax>
  );
};

export default LandingPage;