import React, { useRef, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useSpring, animated } from '@react-spring/three';
import * as THREE from 'three';
import './Heart3D.css';

const Heart = ({ onClick }) => {
  const group = useRef();
  const { scale, position } = useSpring({
    from: { scale: [0, 0, 0], position: [0, 0, 0] },
    to: { scale: [0.15, 0.15, 0.15], position: [0, 0, 0] }, // 40% smaller
    config: { duration: 3000 },
  });

  useFrame(() => {
    group.current.rotation.y += 0.01;
  });

  const heartShape = new THREE.Shape();
  const x = 0, y = 0;
  heartShape.moveTo(x + 5, y + 5);
  heartShape.bezierCurveTo(x + 5, y + 5, x + 4, y, x, y);
  heartShape.bezierCurveTo(x - 6, y, x - 6, y + 7, x - 6, y + 7);
  heartShape.bezierCurveTo(x - 6, y + 11, x - 3, y + 15.4, x + 5, y + 19);
  heartShape.bezierCurveTo(x + 12, y + 15.4, x + 16, y + 11, x + 16, y + 7);
  heartShape.bezierCurveTo(x + 16, y + 7, x + 16, y, x + 10, y);
  heartShape.bezierCurveTo(x + 7, y, x + 5, y + 5, x + 5, y + 5);

  const extrudeSettings = {
    depth: 2,
    bevelEnabled: true,
    bevelSegments: 2,
    steps: 2,
    bevelSize: 1,
    bevelThickness: 1,
  };

  return (
    <animated.group ref={group} scale={scale} position={position} onClick={onClick}>
      <mesh rotation={[Math.PI, 0, 0]} position={[-5, -10, 0]}>
        <extrudeGeometry args={[heartShape, extrudeSettings]} />
        <meshStandardMaterial color="red" />
      </mesh>
    </animated.group>
  );
};

const Heart3D = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [chapterIndex, setChapterIndex] = useState(0);

  const chapters = [
    {
      title: "Velkommen til dit første domæne",
      text: "Hej skat, velkommen til dit første domæne. Det er dit og du kan bruge det til præcis hvad du har lyst til, men jeg håber lidt du vil lave lidt spiludvikling med din kære kæreste ;) <3"
    },
    {
      title: "Nu er vi jo nørder...",
      text: "Dette år har været helt fantastisk med dig, og jeg kan ikke se mit liv uden din latterlige humor, den tæthed og kærlighed du giver mig dag ud og dag ind. Og nu er vi jo liiidt freaky, så derfor tænkte jeg istedet for et klassisk kedeligt fødselsdagsbrev, ville jeg uppe den lidt og tyvstarte på vores horror gamer firma. Håber virkelig du kan lide det. <3"
    },
    {
      title: "Får jeg ikke også en gave?!??",
      text: "Jo selvfølgelig gør du det... Et skide domæne er ikke din gave... Selvom der tilhørende web-server med (siger det bare...)... Jeg har været ret i tvivl hvad jeg skal give pigen der har alt (mig). Så jeg tænkte jeg ville give dig noget jeg også kunne få noget ud af...  Er du klar?"
    },
    {
      title: "Hvad er det så?",
      text: "DET EN REJSE! Vi skal ud på en rejse sammen, gerne her til vinter hvis det er muligt. Du bestemmer hvorhen, og jeg betaler. Om det er en forlænget weekend eller en uge, om det er i Spanien sommerhuset, Polen eller London er helt op til dig. Jeg vil bare gerne ud og opleve verden med dig <3."
    },
    {
      title: "Min og kun min Puttermis",
      text: "Du min lille putter, og jeg håber du ved hvor meget du betyder for mig. Jeg glæder mig til at fejre din fødselsdag, og jeg håber du kan lide min gave. Jeg vil gøre alt for at du har en fantastisk dag, og jeg vil altid være her for dig. Jeg elsker dig min skat <3."
    }
  ];

  const handleClick = () => {
    setShowPopup(true);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleNext = () => {
    setChapterIndex((prevIndex) => (prevIndex + 1) % chapters.length);
  };

  const handleBack = () => {
    setChapterIndex((prevIndex) => (prevIndex - 1 + chapters.length) % chapters.length);
  };

  return (
    <>
      <h1 className="animated-header">KillTower Games</h1>
      <div className="animated-subheader">Tillykke med Fødselsdagen og de 23 år bby!!</div>
      <Canvas className="heart-canvas" camera={{ position: [0, 0, 10], fov: 50 }}>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <Heart onClick={handleClick} />
      </Canvas>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <div className="flag flag1"></div>
            <div className="flag flag2"></div>
            <span className="close" onClick={handleClose}>&times;</span>
            <h2>{chapters[chapterIndex].title}</h2>
            <p>{chapters[chapterIndex].text}</p>
            <div className="buttons">
              <button className="button" onClick={handleBack} disabled={chapterIndex === 0}>Tilbage</button>
              <button className="button" onClick={handleNext} disabled={chapterIndex === chapters.length - 1}>Næste</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Heart3D;